import React from 'react';
import './footer.css';
import footer from '../../images/background-2.jpg';

const Footer = () => {
    return (
        <div className='footer_hero' style={{
            backgroundImage: `linear-gradient(
                to bottom,
                rgba(10,10,10, 1) 100%),
                rgba(10,10,10, 0.6) 50%,
                rgba(10,10,10,1) 0%,
                url(${footer})`
        }}>
        </div>
        
    )
}

export default Footer