import React from "react"
import "./home.css"
import { graphql, navigate, StaticQuery } from "gatsby"

export default () => (
  
    <StaticQuery
      query={graphql`
        query HomeQuery {
          allContentfulBlog(
            limit: 9
            sort: { fields: [createdAt], order: DESC }
            filter: { node_locale: { eq: "en-US" }, home: { eq: true } }
          ) {
            edges {
              node {
                id
                slug
                title

                featuredImage {
                  fluid(maxWidth: 1200, quality: 85) {
                    src
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="feed">
          {data.allContentfulBlog.edges.map(edge => (
            <div
              key={edge.node.id}
              className="card"
              style={{
                backgroundImage: `url(${edge.node.featuredImage.fluid.src})`,
              }}
              onClick={() => navigate(`/blog/${edge.node.slug}`)}
            >
              <p className="card__title">{edge.node.title}</p>
            </div>
          ))}
        </div>
      )}
    ></StaticQuery>

)
